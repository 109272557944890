export const colors = {
  lightPurple: '#D7CFEF', //to be removed
  green: '#166045', //to be replaced
  outlinegray: '#A0A3BD', //to be replaced

  black: '#272E35',
  white: '#FFFFFF',
  burgandy: '#7E0000',

  purple500: '#5A37BC',
  purple100: '#D7CFEF',
  purple50: '#F7F5FC',
  purple900: '#2D1C5E',
  purple800: '#382276',
  purple700: '#44298D',
  purple600: '#4F30A5',
  purple400: '#795DC9',
  purple300: '#9983D6',
  purple200: '#B8A9E2',

  orange500: '#FF621F',
  orange100: '#FFDDC9',
  orange50: '#FFF8F4',
  orange900: '#B30B00',
  orange800: '#C61F00',
  orange700: '#D93400',
  orange600: '#EC4B03',
  orange400: '#FF834A',
  orange300: '#FFA274',
  orange200: '#FFC09F',

  red500: '#FB3746',
  red100: '#FECFD3',
  red50: '#FFF5F6',
  red900: '#7E1C23',
  red800: '#9D222C',
  red700: '#BC2935',
  red600: '#DC303D',
  red400: '#FC5D69',
  red300: '#FD838C',
  red200: '#FDA9AF',

  green500: '#2CC08A',
  green100: '#CCF0E3',
  green50: '#F4FCF9',
  green900: '#166045',
  green800: '#1C7856',
  green700: '#219068',
  green600: '#27A879',
  green400: '#54CCA0',
  green300: '#7CD8B7',
  green200: '#A4E4CD',

  blue500: '#3381FF',
  blue100: '#CEE1FF',
  blue50: '#F5F9FF',
  blue900: '#1A4180',
  blue800: '#20519F',
  blue700: '#2661BF',
  blue600: '#2D71DF',
  blue400: '#5A99FF',
  blue300: '#81B1FF',
  blue200: '#A7C9FF',
  facebookBlue: '#1877F2',
  facebookBlueHover: '#0C3C79',

  yellow500: '#FFC542',
  yellow100: '#FFF1D2',
  yellow50: '#FFFCF6',
  yellow900: '#806321',
  yellow800: '#9F7B29',
  yellow700: '#BF9432',
  yellow600: '#DFAC3A',
  yellow400: '#FFD066',
  yellow300: '#FFDB8A',
  yellow200: '#FFE6AE',

  gray500: '#A0A3BD',
  gray100: '#F0F2F9',
  gray50: '#F4F5FB',
  gray900: '#373950',
  gray800: '#565872',
  gray700: '#757792',
  gray600: '#8D8FA8',
  gray400: '#B8BBD1',
  gray300: '#D4D7E8',
  gray200: '#E1E3F4',
  gray10: '#F7F8FC'
};
