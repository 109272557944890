import React, { FC } from 'react';
import { styled, Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { colors } from '../theme/colors';
interface ProgressSpinnerProps {
  size?: string;
  color?: string;
}

const ProgressSpinner: FC<ProgressSpinnerProps> = ({ size = '3rem', color = colors.purple500 }) => {
  return (
    <>
      <StyledSpinner color={color}>
        <Loader color="inherit" size={size} />
      </StyledSpinner>
    </>
  );
};

export default ProgressSpinner;

const StyledSpinner = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Loader = styled(CircularProgress)<{ color: string }>`
  color: ${({ color }) => (color ? color : colors.purple500)};
`;
