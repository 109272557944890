import React, { useMemo, Suspense, lazy } from 'react';
import { NextPage } from 'next';
import { withSession, withTranslations } from '@/ssr';
import { Box, createTheme, ThemeProvider } from '@mui/material';
import ProgressSpinner from '../components/ProgressSpinner';
const HomeFooter = lazy(() => import('../components/HomePage/HomeFooter'));
const HomeNavbar = lazy(() => import('../components/HomePage/HomeNavbar'));
const HomeHeader = lazy(() => import('../components/HomePage/HomeHeader'));
const ComingSoon = lazy(() => import('../components/HomePage/ComingSoon'));
const StepsToSell = lazy(() => import('../components/HomePage/StepsToSell'));
const OurStory = lazy(() => import('../components/HomePage/OurStory'));

const Index: NextPage = () => {
  const theme = useMemo(() => createTheme(), []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Suspense fallback={<ProgressSpinner size="3rem" />}></Suspense>
        <HomeNavbar />
        <ComingSoon />
        <HomeHeader />
        <StepsToSell />

        {/* <OurStory /> */}
        <HomeFooter />
      </Box>
    </ThemeProvider>
  );
};
export default Index;

export const getServerSideProps = withSession(withTranslations());
